import { FC } from 'react';
import { SKELETON_ELEMENT_SHAPES } from 'components/skeletons/types';

interface SkeletonElementProps {
  elementCount?: number;
  className?: string;
  shape?: (typeof SKELETON_ELEMENT_SHAPES)[keyof typeof SKELETON_ELEMENT_SHAPES];
}

const SHAPE_STYLE = {
  [SKELETON_ELEMENT_SHAPES.CIRCLE]: 'tw-rounded-full',
};

const SkeletonElement: FC<SkeletonElementProps> = ({ elementCount = 1, className = '', shape = null }) => {
  const elements = Array(elementCount)?.fill('');

  return (
    <>
      {elements?.map((_, index) => {
        return (
          <div
            className={`tw-animate-pulse tw-bg-BASE_PRIMARY ${className} ${shape ? SHAPE_STYLE[shape] ?? '' : ''}`}
            key={index}
          >
            &zwnj;
          </div>
        );
      })}
    </>
  );
};

export default SkeletonElement;
