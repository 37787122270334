import { FC, FunctionComponent } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';

export interface MaskedAccountNumberProps {
  maskedAccountNumber?: string;
  wrapperClassName?: string;
  maskClassName?: string;
  textClassName?: string;
  maskCount?: number;
  TextWrapper?: FunctionComponent<{ text: string }>;
}

const MaskedAccountNumber: FC<MaskedAccountNumberProps> = ({
  maskedAccountNumber = '',
  wrapperClassName = 'tw-gap-1.5',
  maskClassName = 'tw-w-[2.5px] tw-h-[2.5px] tw-bg-TEXT_PRIMARY',
  textClassName = 'f-12-300 tw-text-TEXT_PRIMARY',
  maskCount = 2,
  TextWrapper,
}) => {
  const masklist = Array(maskCount).fill('');

  return (
    <div className={`tw-flex tw-items-center tw-flex-row ${wrapperClassName}`}>
      {masklist?.map((_, index) => (
        <div className={`${maskClassName} tw-rounded-full`} key={index}></div>
      ))}
      <Text textClass={textClassName}>
        {TextWrapper ? <TextWrapper text={maskedAccountNumber} /> : maskedAccountNumber}
      </Text>
    </div>
  );
};

export default MaskedAccountNumber;
