import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { Label } from 'destiny/dist/components/molecules/label';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { ESTIMATED_BALANCE_TOOLTIP } from 'modules/accounts-mea/accounts.constants';
import AccountBalance from 'modules/accounts-mea/components/AccountBalance';
import { ICONS } from 'components/icons/constants';
import IconWrapper from 'components/icons/IconWrapper';

interface SMEntityTitleLabelProps {
  data: {
    name: string;
    balance: string;
    balance_currency_code: string;
  };
}

const SMEntityTitleLabel: FC<SMEntityTitleLabelProps> = ({
  data: { name = '', balance = '', balance_currency_code = '' } = {},
}) => {
  return (
    <Label
      title={name}
      description={
        balance &&
        balance_currency_code && (
          <div className='tw-flex tw-items-center tw-gap-1'>
            <AccountBalance
              headerText='Estimated Total Balance:'
              wrapperClassName='tw-flex tw-gap-1 tw-items-center'
              headerClassName='f-14-300 tw-text-TEXT_PRIMARY'
              balanceClassName='f-14-300 tw-text-TEXT_PRIMARY'
              data={{ balance, balance_currency_code }}
            />
            <Tooltip
              tooltipBody={
                <Text textClass='f-12-300 tw-text-white tw-whitespace-normal'>{ESTIMATED_BALANCE_TOOLTIP}</Text>
              }
              tooltipBodyClassName='tw-py-1 tw-px-2 tw-rounded-[5px] tw-min-w-[180px]'
              color={COLORS.TEXT_PRIMARY}
              position={TooltipPositions.TOP}
            >
              <IconWrapper icon={ICONS.INFO_CIRCLE} color={COLORS.TEXT_SECONDARY} width={14} height={14} />
            </Tooltip>
          </div>
        )
      }
      titleClass='f-13-500 tw-text-TEXT_PRIMARY'
      descriptionClass=''
      wrapperClass='tw-flex tw-flex-col tw-gap-1'
    />
  );
};

export default SMEntityTitleLabel;
