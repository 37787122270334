import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { Alert } from 'destiny/dist/components/molecules/alert';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { defaultFn } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { AlertTypes } from 'destiny/dist/constants/molecules/alert';
import { LINKED_ACCOUNT_TYPES, MFA_VALIDATION_STATUS } from 'modules/treasury-account/treasury-account.constants';
import AccountNumberComponent from 'modules/yield/accounts/components/AccountNumberComponent';
import { MapAny } from 'types';
import { LinkAccoundDetailsResponseTypes } from 'types/investments.types';

interface AccountNumberDetailsProps {
  data: Partial<LinkAccoundDetailsResponseTypes>;
  accountStatusClassName?: string;
  handleShowLinkedAccountDetailsSidebar?: (payload?: MapAny) => void;
}

const AccountNumberDetails: FC<AccountNumberDetailsProps> = ({
  data: { account_number = '', otp_validation_status: status, account_linking_type: accountType, id = '' } = {},
  handleShowLinkedAccountDetailsSidebar = defaultFn,
}) => {
  if (accountType === LINKED_ACCOUNT_TYPES.THIRD_PARTY) {
    return (
      <AccountNumberComponent
        accountNumber={account_number}
        accountNumberClassName='f-14-300 tw-text-TEXT_SECONDARY'
        iconProps={{ color: COLORS.TEXT_SECONDARY }}
        wrapperClassName='!tw-gap-2.5'
      />
    );
  }

  switch (status) {
    case MFA_VALIDATION_STATUS.PENDING:
      return (
        <div
          className='tw-flex tw-justify-end tw-cursor-pointer'
          onClick={() => handleShowLinkedAccountDetailsSidebar({ id })}
        >
          <Alert alertType={AlertTypes.PENDING} className='!tw-border-0 !tw-rounded-full !tw-py-2 !tw-px-4'>
            <div className='tw-flex tw-items-center'>
              <SvgSpriteLoader
                id='arrow-right'
                iconCategory={ICON_SPRITE_TYPES.ARROWS}
                width={16}
                height={16}
                color={COLORS.TEXT_SECONDARY}
              />
              <Text textClass='f-12-400 tw-ml-2 tw-whitespace-nowrap'>Complete validation</Text>
            </div>
          </Alert>
        </div>
      );
    case MFA_VALIDATION_STATUS.FAILED:
    case MFA_VALIDATION_STATUS.EXPIRED:
      return (
        <div
          className='tw-flex tw-justify-end tw-cursor-pointer'
          onClick={() => handleShowLinkedAccountDetailsSidebar({ id })}
        >
          <Alert alertType={AlertTypes.PENDING} className='!tw-border-0 !tw-rounded-full !tw-py-2 !tw-px-4'>
            <div className='tw-flex tw-items-center'>
              <SvgSpriteLoader
                id='flip-backward'
                iconCategory={ICON_SPRITE_TYPES.ARROWS}
                width={16}
                height={16}
                color={COLORS.TEXT_SECONDARY}
              />
              <Text textClass='f-12-400 tw-ml-2 tw-whitespace-nowrap'>Resend OTP</Text>
            </div>
          </Alert>
        </div>
      );
    case MFA_VALIDATION_STATUS.SUCCESS:
    default:
      return (
        <AccountNumberComponent
          accountNumber={account_number}
          accountNumberClassName='f-14-300 tw-text-TEXT_SECONDARY'
          iconProps={{ color: COLORS.TEXT_SECONDARY }}
          wrapperClassName='!tw-gap-2.5'
        />
      );
  }
};

export default AccountNumberDetails;
