import { FC } from 'react';
import AccountBalance from 'modules/accounts-mea/components/AccountBalance';

interface SMEntityAccountBalanceProps {
  data: { balance: string; balance_currency_code: string; filtered_balance: string };
}

const SMEntityAccountBalance: FC<SMEntityAccountBalanceProps> = ({
  data: { balance, balance_currency_code, filtered_balance },
}) => {
  return (
    <AccountBalance
      balanceClassName='f-14-500 tw-text-TEXT_PRIMARY'
      data={{
        balance: filtered_balance ?? balance ?? '',
        balance_currency_code: balance_currency_code,
        currency_code: balance_currency_code,
      }}
      showHeader={false}
    />
  );
};

export default SMEntityAccountBalance;
