import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';

interface EntityCollapseAllProps {
  collapseAll: boolean;
}

const EntityCollapseAll: FC<EntityCollapseAllProps> = ({ collapseAll = false }) => {
  return (
    <Tooltip
      tooltipBody={
        <Text textClass='f-12-400 tw-text-white tw-truncate'>
          {collapseAll ? 'Expand all entities' : 'Collapse all entities'}
        </Text>
      }
      tooltipBodyClassName='tw-py-1 tw-px-2 tw-rounded-[5px]'
      color={COLORS.TEXT_PRIMARY}
      position={TooltipPositions.BOTTOM}
    >
      <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-border tw-border-DIVIDER_GRAY tw-p-1 tw-w-6 tw-h-6 tw-cursor-pointer'>
        <SvgSpriteLoader
          id='minus'
          iconCategory={ICON_SPRITE_TYPES.GENERAL}
          width={14}
          height={14}
          color={COLORS.TEXT_PRIMARY}
        />
      </div>
    </Tooltip>
  );
};

export default EntityCollapseAll;
