import { FC, FunctionComponent } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { breakAmountWithDelimiter } from 'utils/common';

export interface AccountBalanceProps {
  data?: { balance: string; currency_code?: string; balance_currency_code?: string; usd_display_balance?: string };
  showHeader?: boolean;
  showCurrencyOnly?: boolean;
  headerText?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  balanceClassName?: string;
  usdBalanceClassName?: string;
  checkBalanceValidity?: boolean;
  TextWrapper?: FunctionComponent<{ text: string }>;
  isFormattingRequired?: boolean;
}

const AccountBalance: FC<AccountBalanceProps> = ({
  data: { balance, currency_code, balance_currency_code, usd_display_balance } = {},
  showHeader = true,
  showCurrencyOnly = false,
  headerText = 'BALANCE',
  wrapperClassName = 'tw-flex tw-flex-col tw-gap-1',
  headerClassName = 'f-10-600  tw-text-TEXT_TERTIARY',
  balanceClassName = 'f-14-400 tw-text-TEXT_PRIMARY',
  usdBalanceClassName = 'f-12-300  tw-text-TEXT_TERTIARY',
  checkBalanceValidity = false,
  TextWrapper,
  isFormattingRequired = false,
}) => {
  const formattedBalance = isFormattingRequired ? breakAmountWithDelimiter(balance ?? '0') : balance ?? '0';
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);
  const validBalance = Number(balance?.toString()?.replace(/,/g, '')) ?? undefined;
  const currencyCode = currency_code ?? balance_currency_code ?? '';

  const balanceText = `${currencyCodeAndNameMap?.[currencyCode] ?? currencyCode}${
    balance && !showCurrencyOnly ? ` ${formattedBalance}` : ''
  }`;

  const usdBalanceText = usd_display_balance ? `$${usd_display_balance}` : '';

  return (
    <div className={wrapperClassName}>
      {(!checkBalanceValidity || (checkBalanceValidity && !!validBalance) || showCurrencyOnly) && (
        <>
          {showHeader && !showCurrencyOnly && (
            <Text textClass={headerClassName}>{TextWrapper ? <TextWrapper text={headerText} /> : headerText}</Text>
          )}
          <Text textClass={balanceClassName}>{TextWrapper ? <TextWrapper text={balanceText} /> : balanceText}</Text>
        </>
      )}
      {usdBalanceText && (
        <Text textClass={usdBalanceClassName}>
          {TextWrapper ? <TextWrapper text={usdBalanceText} /> : usdBalanceText}
        </Text>
      )}
    </div>
  );
};

export default AccountBalance;
