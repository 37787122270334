import { FC } from 'react';
import { Alert } from 'destiny/dist/components/molecules/alert';
import { defaultFn } from 'destiny/dist/constants';
import { AlertTypes } from 'destiny/dist/constants/molecules/alert';
import { useGetCountdown } from 'hooks';
import {
  ACCOUNT_LINK_STATUS,
  LINKED_ACCOUNT_TYPES,
  MFA_VALIDATION_STATUS,
} from 'modules/treasury-account/treasury-account.constants';
import { defaultFnType } from 'types';
import { LinkAccoundDetailsResponseTypes } from 'types/investments.types';
import Avatar from 'components/avatar/Avatar';

interface AccountHolderNameProps {
  data: Partial<LinkAccoundDetailsResponseTypes>;
  accountStatusOverrideClassName?: string;
  getLinkedAccountDetails?: defaultFnType;
}

const AccountHolderName: FC<AccountHolderNameProps> = ({
  data: {
    account_holder_name = '',
    account_status = '',
    account_linking_type = '',
    otp_validation_status = '',
    otp_expires_in_seconds = 0,
  } = {},
  getLinkedAccountDetails = defaultFn,
  accountStatusOverrideClassName = '!tw-inline-block !tw-border-0 !tw-rounded !tw-px-1 !tw-py-0.5 !tw-mb-0 f-10-400',
}) => {
  const { hours, minutes, seconds } = useGetCountdown(otp_expires_in_seconds, () => getLinkedAccountDetails());

  const getAccountLinkingStatusAlert = (accountType: string) => {
    switch (account_status) {
      case ACCOUNT_LINK_STATUS.FAILED:
        return (
          <Alert alertType={AlertTypes.ERROR} className={accountStatusOverrideClassName}>
            Linking is failed
          </Alert>
        );

      case ACCOUNT_LINK_STATUS.PENDING: {
        if (accountType === LINKED_ACCOUNT_TYPES.THIRD_PARTY) {
          return (
            <Alert alertType={AlertTypes.PENDING} className={accountStatusOverrideClassName}>
              Linking in progress
            </Alert>
          );
        }
        switch (otp_validation_status) {
          case MFA_VALIDATION_STATUS.PENDING:
            return (
              <Alert className={accountStatusOverrideClassName} alertType={AlertTypes.SUCCESS}>
                OTP valid for {`${hours}:${minutes}:${seconds}`}
              </Alert>
            );
          case MFA_VALIDATION_STATUS.FAILED:
          case MFA_VALIDATION_STATUS.EXPIRED:
            return (
              <Alert className={accountStatusOverrideClassName} alertType={AlertTypes.ERROR}>
                OTP expired
              </Alert>
            );
          case MFA_VALIDATION_STATUS.SUCCESS:
          default:
            return (
              <Alert alertType={AlertTypes.PENDING} className={accountStatusOverrideClassName}>
                Linking in progress
              </Alert>
            );
        }
      }
      case ACCOUNT_LINK_STATUS.SUCCESS:
      default:
        return <></>;
    }
  };

  return (
    <div className='tw-flex tw-items-center tw-gap-4 tw-max-w-[25vw]'>
      <Avatar name={account_holder_name} className='tw-w-7 tw-h-7' />
      <div className='tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden'>
        <div className='f-14-300 tw-text-TEXT_PRIMARY tw-truncate'>{account_holder_name}</div>
        <div className='tw-min-w-[180px] tw-max-w-[25vw] tw-truncate'>
          {account_status !== ACCOUNT_LINK_STATUS.ACTIVE && getAccountLinkingStatusAlert(account_linking_type)}
        </div>
      </div>
    </div>
  );
};

export default AccountHolderName;
