export const ACCOUNT_LINK_STATUS = {
  SUCCESS: 'succeeded',
  ACTIVE: 'active',
  PENDING: 'initiated',
  FAILED: 'failed',
};

export const LINKED_ACCOUNT_TYPES = {
  FIRST_PARTY: 'first_party',
  THIRD_PARTY: 'third_party',
};

export const MFA_VALIDATION_STATUS = {
  SUCCESS: 'mfa_validation_succeeded',
  PENDING: 'mfa_validation_pending',
  EXPIRED: 'mfa_otp_expired',
  FAILED: 'mfa_validation_failed',
};

export const ACCOUNT_INITIATED_HELPER_TEXT = `Please note that it may take up to 48 hours to link your account. We will notify you once account has been successfully linked.`;
