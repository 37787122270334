import { FC, useRef, useState } from 'react';
import { DELETE_BLACK, OPTIONS } from 'constants/icons';
import SvgSpriteLoader, { SvgSpriteLoaderProps } from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useOnClickOutside } from 'hooks';
import Image from 'next/image';

const menuItemStyle = 'f-16-400 tw-p-6 hover:tw-bg-BASE_SECONDARY tw-cursor-pointer';

interface AccountNumberComponentPropsTypes {
  accountNumber: string;
  accountNumberClassName?: string;
  wrapperClassName?: string;
  iconProps?: Partial<SvgSpriteLoaderProps>;
}

const AccountNumberComponent: FC<AccountNumberComponentPropsTypes> = ({
  accountNumber,
  accountNumberClassName = '',
  wrapperClassName = '',
  iconProps = {},
}) => {
  const [isshowMenu, setIsShowMenu] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useOnClickOutside(ref, () => {
    setIsShowMenu(false);
  });

  return (
    <div className={`tw-flex tw-gap-3 tw-justify-end ${wrapperClassName}`}>
      <SvgSpriteLoader
        id='building-08'
        iconCategory={ICON_SPRITE_TYPES.GENERAL}
        width={20}
        height={20}
        {...iconProps}
      />
      <span className={accountNumberClassName}>{accountNumber}</span>
      <div className='tw-relative tw-hidden' ref={ref}>
        <div role='presentation' onClick={() => setIsShowMenu(!isshowMenu)}>
          <Image width={4} height={16} src={OPTIONS} alt='treasury icon' className='tw-cursor-pointer' />
        </div>
        {isshowMenu && (
          <div className='tw-z-10 tw-absolute tw-bg-white tw-min-w-[217px] tw- tw-right-0 tw-border-[0.5px] tw-border-DIVIDER_GRAY tw-rounded-lg tw-overflow-hidden'>
            <div className={`tw-flex tw-items-center ${menuItemStyle}`}>
              <div className='tw-w-4 tw-h-4'>
                <Image width={16} height={16} src={DELETE_BLACK} alt='right-arrow' />
              </div>
              <span className='tw-ml-5'>Remove</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountNumberComponent;
