import { FC, ReactElement } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { LabelProps } from 'destiny/dist/types';
import { MapAny } from 'types';

interface LogoLabelInfoProps {
  wrapperClassName?: string;
  logoWrapperClassName?: string;
  logoLabelData?: { logo?: ReactElement; title?: ReactElement | string; subtitle?: ReactElement | string };
  labelProps?: LabelProps;
  customLabel?: ReactElement;
  formatData?: (payload: MapAny) => MapAny;
}

const LogoLabelInfo: FC<LogoLabelInfoProps> = ({
  wrapperClassName = 'tw-gap-4',
  logoWrapperClassName = 'tw-w-8 tw-h-8',
  logoLabelData = null,
  formatData,
  labelProps = {},
  customLabel = null,
  ...rest
}) => {
  const formattedData = logoLabelData ?? formatData?.(rest);
  const { logo, title, subtitle } = formattedData || { logo: '', title: '', subtitle: '' };

  return (
    <div className={`tw-flex tw-flex-row tw-items-center  ${wrapperClassName}`}>
      {logo && (
        <div className={`tw-flex tw-items-center tw-justify-center tw-relative ${logoWrapperClassName}`}>{logo}</div>
      )}
      {customLabel ?? (
        <Label
          title={title}
          description={subtitle}
          titleClass='f-14-400 tw-text-TEXT_PRIMARY tw-truncate'
          descriptionClass='f-12-300 tw-text-TEXT_SECONDARY tw-truncate'
          wrapperClass='tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden'
          {...labelProps}
        />
      )}
    </div>
  );
};

export default LogoLabelInfo;
