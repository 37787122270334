import { useMemo } from 'react';
import { toast as reactToastify } from 'react-toastify';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';

const CustomToast = ({ text = '', type = '' }) => {
  const handleCloseClick = () => {
    reactToastify.dismiss();
  };

  const iconProps = useMemo(() => {
    switch (type) {
      case 'success':
        return {
          id: 'check-circle',
          iconCategory: ICON_SPRITE_TYPES.GENERAL,
          color: COLORS.GREEN_PRIMARY,
        };
      case 'error':
        return {
          id: 'x-circle',
          iconCategory: ICON_SPRITE_TYPES.GENERAL,
          color: COLORS.RED_PRIMARY,
        };
      case 'warn':
        return {
          id: 'alert-circle',
          iconCategory: ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK,
          color: COLORS.ORANGE_SECONDARY,
        };
      default:
        return {
          id: 'alert-circle',
          iconCategory: ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK,
          color: COLORS.GREEN_PRIMARY,
        };
    }
  }, [type]);

  return (
    <div className='f-16-300 tw-flex tw-items-center tw-gap-6 tw-w-full tw-text-TEXT_PRIMARY'>
      <SvgSpriteLoader {...iconProps} width={24} height={24} />
      {text}
      <SvgSpriteLoader
        id='x-close'
        onClick={handleCloseClick}
        iconCategory={ICON_SPRITE_TYPES.GENERAL}
        width={24}
        height={24}
        className='tw-ml-auto'
        color={COLORS.TEXT_TERTIARY}
      />
    </div>
  );
};

export const toast = {
  success: (message: string) => {
    reactToastify.success(<CustomToast text={message} type='success' />);
  },
  error: (message: string) => {
    reactToastify.error(<CustomToast text={message} type='error' />);
  },
  warn: (message: string) => {
    reactToastify.warn(<CustomToast text={message} type='warn' />);
  },
};
