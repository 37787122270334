import React, { FC, memo } from 'react';
import Image from 'next/image';
import { getFirstLetters, getRandomColor } from 'utils/common';

type AvatarProps = {
  styleClassName?: string;
  className?: string;
  name: string;
  imageUrl?: string;
  backgroundColor?: string;
};

const Avatar: FC<AvatarProps> = ({
  styleClassName = 'f-12-300 tw-font-normal tw-h-8 tw-min-w-[32px]',
  className = '',
  name,
  imageUrl,
  backgroundColor,
}) => (
  <>
    <div
      className={`${styleClassName} tw-flex tw-justify-center tw-items-center tw-rounded-full tw-relative tw-text-white
      tw-cursor-pointer tw-hover:tw-opacity-70 ${className}`}
      style={{ backgroundColor: backgroundColor || getRandomColor() }}
    >
      {imageUrl ? <Image src={imageUrl} alt={name} fill /> : <>{getFirstLetters(name)}</>}
    </div>
  </>
);

export default memo(Avatar);
