import { FC } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';

interface ChevronUpDownToggleProps {
  isOpen: boolean;
  color?: string;
}

const ChevronUpDownToggle: FC<ChevronUpDownToggleProps> = ({ isOpen = false, color = '' }) => {
  return (
    <SvgSpriteLoader
      id={isOpen ? 'chevron-down' : 'chevron-right'}
      iconCategory={ICON_SPRITE_TYPES.ARROWS}
      width={24}
      height={24}
      color={color}
    />
  );
};

export default ChevronUpDownToggle;
